import { memo, createContext, useEffect, useMemo, useState, useCallback, useContext, useRef } from 'react'
import type { Dispatch, SetStateAction } from 'react'
import { useRouter } from 'next/router'
import { useMutation } from '@apollo/client'
import { AnimatePresence, motion } from 'framer-motion'
import { Button, deleteVariants, deleteButtonVariants, figureVariants, defaultVariants, ButtonWrapper, Counter, Units, counterVariants } from '@dy/commons/components'
import { useDeviceType, useModal, useCart, useToast } from '@dy/commons/hooks'
import { MODALS } from '@dy/commons/context'
import type { TypeTranslationsJsonKeys } from '@/context'
import { CUSTOMER_ADD_TO_CART, CUSTOMER_UPDATE_CART_ITEM, CUSTOMER_DELETE_FROM_CART, GET_CART } from '@/api'
import { cookie, CART_LINE_TYPE, getPathnameType, hasPrivateStore, getDynamicText, pushAnalyticsEvent } from '@dy/commons/utils'
import type { TypeCartLine } from '@dy/commons/schema'
import { useTranslator, useActiveVariant, useCustomer } from '@/hooks'
// import type { TypeCart, TypeCartLine } from '@dy/commons/schema'

//trigger deploy
type NewCartItemsContextType = {
  cartItems: any[],
  setCartItems:Dispatch<SetStateAction<any[]>>
}

type TypeButtonDelete = {
  productCount: number
  backToSingleUnit: boolean
  minSaleUnits: number
  readonly isPDP: boolean
  onClick: (increase) => void
  onClickBin: () => void
}

export const NewCartItemsContext = createContext<NewCartItemsContextType | null>(null)

const ButtonDelete = memo(({ productCount, backToSingleUnit, minSaleUnits, isPDP, onClick, onClickBin }:TypeButtonDelete) => {
  const { isMobile, size } = useDeviceType()
  const animate = useMemo(() => isPDP ? 'expandedPDP' : (size.width >= 603 && size.width < 768) ? 'expandedNexus' : isMobile ? 'expandedMobile' : (size.width >= 1650) ? 'expandedWide':'expandedDesktop', [isMobile, isPDP, size.width])
  const initialAndExit = useMemo(() => isPDP ? 'collapsedPDP' : 'collapsed', [isPDP])

  return (
    <motion.div className='btn-bin' initial={initialAndExit} variants={deleteVariants} exit={initialAndExit} animate={animate}>
      <Button circle={true} noHoverEffect={true} bgType={'red'} onClick={() => { productCount === minSaleUnits ? onClickBin() : onClick(false) }} name='Remove unit' initial='collapsed' variants={deleteButtonVariants} animate='expanded' exit='collapsed'>
        <motion.figure key={`one-${productCount === minSaleUnits}`} initial={'binCollapsed'} variants={figureVariants} animate={backToSingleUnit ? 'fadeIn' : productCount === minSaleUnits ? 'binExpanded' : 'fadeOut'}>
          {/* eslint-disable-next-line @next/next/no-img-element */}
          <img src='/images/svg/btn-bin.svg' alt='Icon bin' width='16' height='19'/>
        </motion.figure>
        <motion.figure key={`two-${productCount > minSaleUnits}`} initial={{ opacity: 0, scale: 0, rotate: 0 }} animate={productCount > minSaleUnits ? { opacity: 1, scale: 1, rotate: 0 } : { opacity: 0, scale: 0, rotate: 90 }} transition={{ duration: .3 }} exit={{ opacity: 0, rotate: 90 }} className='figure-minus'>
          {/* eslint-disable-next-line @next/next/no-img-element */}
          <img src='/images/svg/btn-minus.svg' alt='Icon minus sign' width='20' height='3' className='img-minus'/>
        </motion.figure>
      </Button>
    </motion.div>
  )
})

const AddButton = ({ onClick, className = '' }) => {
  return (
    <motion.div className={className} initial='collapsed' variants={defaultVariants} exit='collapsed' animate={'expanded'}>
      <Button circle={true} bgType={'red'} onClick={onClick}>
        <figure>
          {/* eslint-disable-next-line @next/next/no-img-element */}
          <img src='/images/svg/btn-sign-icon.svg' alt='Icon add to cart' width='16' height='16'/>
        </figure>
      </Button>
    </motion.div>
  )
}

type TypeAddToCart = {
  data?: TypeCartLine | {
    [key:string]: any,
    variant: {
      id: number,
      bigcommerceId: string | number,
      minSaleUnits?: number
    }
  },
  isPDP?: boolean
  setHasUnitsInCart?: any,
  stock: number,
  isCart?: boolean,
  displayStockWarning?: boolean
  notAvailable?: boolean
  inventoryTracking: boolean
  shouldTriggerHowToBuyModal?: boolean
}

export const AddToCart = memo(({ data, stock = 0, setHasUnitsInCart = null, isPDP = false, isCart = false, displayStockWarning = false, notAvailable = false, inventoryTracking = null, shouldTriggerHowToBuyModal = true }:TypeAddToCart) => {
  const { variant } = data
  const { locale, pathname, push } = useRouter()
  const { t } = useTranslator()
  const { isMobile, size } = useDeviceType()
  // const { toggle:openModalPublicInterested } = useModal(MODALS.PUBLIC_INTERESTED)
  const { toggle:openModalAuth } = useModal(MODALS.AUTH)
  const { toggle:openModalComercial } = useModal(MODALS.COMERCIAL)
  const { cardSelected, removeActiveVariant } = useActiveVariant()
  const { customer } = useCustomer()
  const { addToast } = useToast()

  const wrapperRef = useRef(null)
  const [productCount, setProductCount] = useState(0)
  const [inputUnitsValue, setInputUnitsValue] = useState(0)
  const minSaleUnits = useMemo(() => typeof variant.minSaleUnits !== 'number' ? 1 : variant.minSaleUnits, [variant])

  // Grouping clicks to trigger less CMS API request
  const [clickTimestamp, setClickTimestamp] = useState(0)
  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | null>(null)

  const isPrivate = useMemo(() => getPathnameType(pathname) === 'private',[pathname])

  const animate = useMemo(() => isPDP ? 'expandedPDP' : (size.width >= 603 && size.width <768) ? 'expandedNexus': isMobile ? 'expandedMobile' : (size.width >= 1650) ? 'expandedWide': 'expandedDesktop', [isMobile, isPDP, size.width])
  const [backToSingleUnit, setBackToSingleUnit] = useState(false)
  const { setCartItems } = useContext(NewCartItemsContext) || {}

  // Our react cart Context
  const { cartLines, addToCart, removeFromCart, deleteFromCart, bulkUpdateCart } = useCart()
  // graphQL mutations
  const [cartLinesAdd, { data:dataAfterAdd }] = useMutation(CUSTOMER_ADD_TO_CART, {
    fetchPolicy: 'no-cache'
  })
  const [cartLinesUpdate, { data:dataAfterUpdate }] = useMutation(CUSTOMER_UPDATE_CART_ITEM, {
    fetchPolicy: 'no-cache',
    update: (cache) => {
      // Read the data from the cache for the relevant query
      // const cachedData: { customer: TypeCart['customer'] } | null = cache.readQuery({ query: GET_CART })
      let cachedData = null
      if(cache) cachedData = cache.readQuery({ query: GET_CART })
      // console.log('⭕️ cache', cache)
      console.log('⭕️⭕️ cD', cachedData)
      // console.log('⭕️ cartLinesUpdate', cartLinesUpdate)

      // // Check if cachedData and customer are not null before accessing properties
      // if (cachedData && cachedData.customer && cachedData.customer.cart) {
      //   // Update cart properties with the mutation response
      //   cachedData.customer.cart = { ...cachedData.customer.cart, ...cartLinesUpdate.cart }
      //   // Write the updated data back to the cache
      //   cache.writeQuery({
      //     query: GET_CART,
      //     data: { customer: { ...cachedData.customer } },
      //   })
      // } else if (!cachedData) {
      //   cache.writeQuery({
      //     query: GET_CART,
      //     data: { customer: { cart: { ...cartLinesUpdate.cart, boughtTogether: [] }}}
      //   })
      // }
    }
  })
  const [cartLinesDelete, { data:dataAfterDelete }] = useMutation(CUSTOMER_DELETE_FROM_CART, {
    fetchPolicy: 'no-cache'
  })

  const onInputUnitsChange = useCallback(e => {
    setInputUnitsValue(e.target.value)
  }, [setInputUnitsValue])

  // NEW INPUT UNITS
  const getValidValue = useCallback((value, minUnits, stock, notification = false) => {
    let numb = Number(value) ?? minUnits
    if (numb > stock) {
      numb = stock
      if (notification) {
        addToast({
          message: getDynamicText(t('product_page.adjusted_stock' as TypeTranslationsJsonKeys) as string, {
            units: numb
          }),
          type: 'info'
        })
      }
    }
    if (numb % minUnits === 0) return numb
    else {
      // Find the nearest lower multiple of minUnits
      const lowerMultiple = Math.floor(numb / minUnits) * minUnits
      // Find the nearest higher multiple of minUnits
      const higherMultiple = lowerMultiple + minUnits

      let validMultiple = null
      // Determine which multiple is closer to units
      if ((numb - lowerMultiple) < (higherMultiple - numb)) {
        validMultiple = lowerMultiple
      } else {
        validMultiple = higherMultiple
      }

      if(validMultiple === 0 && higherMultiple <= stock) validMultiple = higherMultiple

      if(notification) {
        addToast({
          message: getDynamicText(t('product_page.adjusted_units' as TypeTranslationsJsonKeys) as string, {
            units: validMultiple
          }),
          type: 'info'
        })
      }
      return validMultiple
    }
  }, [addToast, t])

  const updateUnit = useCallback(async (increase = true) => {
    let incomingValue = (inputUnitsValue > 0 && inputUnitsValue !== productCount) ? getValidValue(inputUnitsValue, minSaleUnits, stock, true) : increase ? (productCount + minSaleUnits) : (productCount - minSaleUnits)
    console.log('incomingValue', incomingValue)
    if (incomingValue < 0) incomingValue = increase ? minSaleUnits : 0
    setBackToSingleUnit(incomingValue === minSaleUnits)
    setHasUnitsInCart && setHasUnitsInCart(incomingValue > 0)

    setProductCount(incomingValue)
    // NEW INPUT UNITS
    setInputUnitsValue(incomingValue)

    const variantId = variant.id
    const existingLineItem = cartLines.find(item => item?.variant?.id === variantId)
    let incomingLineItem = null

    if (typeof existingLineItem === 'object') {
      incomingLineItem = { ...existingLineItem }
      incomingLineItem.quantity = incomingValue
    }

    // cliker
    const now = Date.now()
    if (timeoutId && (now - clickTimestamp < 900)) clearTimeout(timeoutId)

    const newTimeoutId = setTimeout(async () => {
      const DYCustomerToken = cookie.get('DY_CUSTOMER_TOKEN')
      // Update CMS
      const mutationData = {
        variables: {
          variantId,
          quantity: incomingValue,
          cartLineType: CART_LINE_TYPE.PHYSICAL
        },
        context: {
          isPrivatePath: true,
          DYCustomerToken
        }
      }

      if (increase && productCount === 0 && (incomingValue/minSaleUnits === 1)) {
        console.log('ADD')
        await cartLinesAdd(mutationData).catch((error) => {
          console.warn('Error with mutation: cartLinesAdd:', error)
        })
        pushAnalyticsEvent('add_to_cart', { ...data, variant, units: incomingValue })
      } else if (!increase && incomingValue === 0) {
        console.log('DELETE')
        await cartLinesDelete(mutationData).catch((error) => {
          console.warn('Error with mutation: cartLinesDelete:', error)
        })
        pushAnalyticsEvent('remove_from_cart', { ...data, variant, units: incomingValue })
      } else {
        console.log('UPDATE')
        await cartLinesUpdate(mutationData).catch((error) => {
          console.warn('Error with mutation: cartLinesUpdate:', error)
        })
        pushAnalyticsEvent('add_to_cart', { ...data, variant, units: incomingValue })
      }
      // End update CMS
    }, 900)

    setTimeoutId(newTimeoutId)
    setClickTimestamp(now)
    // End clicker

    // Update context
    if (incomingLineItem) {
      if (increase) addToCart(incomingLineItem)
      else removeFromCart(incomingLineItem)
    }
  }, [minSaleUnits, addToCart, cartLines, cartLinesAdd, cartLinesUpdate, cartLinesDelete, productCount, removeFromCart, setHasUnitsInCart, variant, clickTimestamp, timeoutId, inputUnitsValue, getValidValue, stock])

  const onInputUnitsKeyUp = useCallback(e => {
    if (e.code === 'Enter' || e.key === 'Enter' || e.keyCode === 13) {
      console.log('Enter pressed ⤴️ !!!', inputUnitsValue)
      const val = getValidValue(inputUnitsValue, minSaleUnits, stock)
      e.target.value = val
      updateUnit()
    }
  }, [updateUnit, inputUnitsValue, getValidValue, minSaleUnits, stock])

  const onBlur = useCallback((e) => {
    // Check if the new focused element is outside the wrapperRef
    if (!wrapperRef.current.contains(e.relatedTarget)) {
      setInputUnitsValue(productCount)
      e.target.value = productCount
    }
  }, [productCount])

  const deleteCartLine = async () => {
    const variantId = variant.id
    deleteFromCart(variantId)
    setProductCount(0)
    setInputUnitsValue(0)

    const DYCustomerToken = cookie.get('DY_CUSTOMER_TOKEN')

    try {
      cartLinesDelete({
        variables: {
          variantId
        },
        context: {
          isPrivatePath: true,
          DYCustomerToken
        }
      })
    } catch (e) {
      console.warn('cartLinesDelete catch: ', e)
    }
  }

  // Check if variant product exist in the cart and update it's quantity
  useEffect(() => {
    const item = Array.isArray(cartLines) ? cartLines.find(item => item?.variant?.id === variant.id) : false
    if (item) {
      setProductCount(item.quantity)
      setInputUnitsValue(item.quantity)
      setBackToSingleUnit(variant.minSaleUnits === item.quantity)
      setHasUnitsInCart && setHasUnitsInCart(item.quantity > 0)
    } else {
      setProductCount(0)
      setInputUnitsValue(0)
      setBackToSingleUnit(false)
      setHasUnitsInCart && setHasUnitsInCart(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cartLines, variant.id, variant.minSaleUnits])

  useEffect(() => {
    if (!dataAfterAdd) return
    let cartRes = dataAfterAdd?.cartLinesAdd?.cart
    if(Array.isArray(cartRes?.cartLines)) {
      const newItemCart = cartRes.cartLines.find(item => item?.variant?.id ===  variant?.id)
      if(typeof newItemCart === 'object') {
        setCartItems && setCartItems(prevValue => {
          const prevArray =  [...prevValue]
          const matchedCartItem = prevArray.indexOf(item => item.variant?.id === newItemCart.variant?.id)
          if(matchedCartItem === -1) return [...prevValue, newItemCart]
          else {
            prevArray[matchedCartItem] = newItemCart
            return prevArray
          }
        })
      }
    }
    console.log('📀📀 CMS cartRes dataAfterAdd', cartRes)
    if(!!cartRes && typeof cartRes === 'object') {
      const { cartLines, bigcommerceId:cartId, subtotalExTax, totalExTax, deliveryDate, deliveryDateOptions, multipleDeliveryDates, discountAmount } = cartRes
      bulkUpdateCart(cartLines, cartId, subtotalExTax, totalExTax, deliveryDate, deliveryDateOptions, multipleDeliveryDates, discountAmount)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataAfterAdd])

  useEffect(() => {
    if (!dataAfterUpdate) return
    let cartRes = dataAfterUpdate?.cartLinesUpdate?.cart

    console.log('📀📀 CMS cartRes dataAfterUpdate', cartRes)
    if(!!cartRes && typeof cartRes === 'object') {
      const { cartLines, bigcommerceId: cartId, subtotalExTax, totalExTax, deliveryDate, deliveryDateOptions, multipleDeliveryDates, discountAmount } = cartRes
      bulkUpdateCart(cartLines, cartId, subtotalExTax, totalExTax, deliveryDate, deliveryDateOptions, multipleDeliveryDates, discountAmount)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataAfterUpdate])

  useEffect(() => {
    if (!dataAfterDelete) return
    let cartRes = dataAfterDelete?.cartLinesDelete?.cart

    console.log('📀📀 CMS cartRes dataAfterDelete', cartRes)
    if(!!cartRes && typeof cartRes === 'object') {
      const { cartLines, bigcommerceId: cartId, subtotalExTax, totalExTax, deliveryDate, deliveryDateOptions, multipleDeliveryDates, discountAmount } = cartRes

      bulkUpdateCart(cartLines, cartId, subtotalExTax, totalExTax, deliveryDate, deliveryDateOptions, multipleDeliveryDates, discountAmount)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataAfterDelete])

  useEffect(() => {
    // Clean up the timeout when the component unmounts
    return () => {
      if (timeoutId) clearTimeout(timeoutId)
    }
  }, [timeoutId])

  const redirectToPage = () => {
    const isPanettone = data.product.name.toLowerCase().includes('panettone')
    if(isPanettone) return
    if(cardSelected) removeActiveVariant()
    if(locale === 'de-DE') {
      push('/page/kontaktieren-sie-uns')
    } else if(locale === 'pt-PT') {
      push('/page/contacte-nos')
    }
  }

  return (
    <>
      <ButtonWrapper ref={wrapperRef} className={`${isPDP ? 'is-pdp' : isCart ? 'is-cart' : ''}`}>
        {isPDP ?
          <Button className={`gtm_add-to-cart btn-add--pdp ${productCount > 0 && ' has-units'} ${(isPrivate && (inventoryTracking && stock < minSaleUnits)) ? ' no-stock' : notAvailable ? ' not-available' : ''}`} bgType={'red'} onClick={isPrivate ? updateUnit : locale === 'de-DE' || locale === 'pt-PT' ?  redirectToPage :  shouldTriggerHowToBuyModal ? openModalComercial : null}>
            {isPrivate ? t(!inventoryTracking || stock > minSaleUnits ? 'commons.actions.add_to_cart' : 'product_page.product.no_stock') as string : t('commons.actions.how_to_buy') as string}
          </Button>
          :
          <>
            {(isPrivate && ((inventoryTracking && stock < minSaleUnits) || notAvailable)) ?
              <Button className={`gtm_add-to-cart btn-add insufficient-stock ${cardSelected ? 'selected-card' : ''}`} circle={cardSelected ? true : false} bgType={'red'} disabled name={t('product_page.product.no_stock') as string}>
                {cardSelected ?
                  <figure>
                    <img src='/images/svg/btn-sign-icon.svg' alt='Icon add to cart' width='16' height='16'/>
                  </figure>
                  :
                  <>{notAvailable ? t('product_page.product_main.not_available') : t('product_page.product.no_stock')}</>
                }
              </Button>
              :
              <>
                {/* isPrivate was added by marketing decision to hide plus button until fully release the public site with the signup enabled */}
                {isPrivate && <Button className={`gtm_add-to-cart btn-add ${(productCount + minSaleUnits > stock && inventoryTracking) ? ' insufficient-stock' : ''}`} circle={true} bgType={'red'} onClick={isPrivate ? updateUnit : hasPrivateStore[locale] ? openModalAuth : openModalComercial} name={t('commons.actions.add_to_cart') as string}>
                  <figure>
                    {/* eslint-disable-next-line @next/next/no-img-element */}
                    <img src='/images/svg/btn-sign-icon.svg' alt='Icon add to cart' width='16' height='16'/>
                  </figure>
                </Button>}
              </>
            }
          </>
        }
        <AnimatePresence mode='wait'>
          {productCount > 0 && (stock >= productCount || !inventoryTracking) &&
          <>
            <Counter className={`counter ${isPDP ? ' is-pdp' : ''}`} isPDP={isPDP} initial={isPDP ? 'collapsedPDP' : (size.width >= 603 && size.width <= 768) ? 'collapsedNexus' : isMobile ? 'collapsedMobile' : (size.width >= 1650) ? 'collapsedWide': 'collapsedDesktop'} variants={counterVariants} exit={isPDP ? 'collapsedPDP' : (size.width >= 603 && size.width <= 768) ? 'collapsedNexus' : isMobile ? 'collapsedMobile' : (size.width >= 1650) ? 'collapsedWide':'collapsedDesktop'} animate={animate}>
              <Units key={productCount} className='units' initial={{ y: -15, opacity: 0 }} animate={{ y: 0, opacity: 1 }} transition={{ duration: .3 }} defaultValue={productCount} type='text' pattern='^([1-9][\d]{0,5}|0)$' onChange={onInputUnitsChange} onKeyUp={onInputUnitsKeyUp} onBlur={onBlur}/>
            </Counter>
            <ButtonDelete productCount={productCount} isPDP={isPDP} backToSingleUnit={backToSingleUnit} minSaleUnits={minSaleUnits} onClick={updateUnit} onClickBin={deleteCartLine} />
            {isPDP && <AddButton className={`gtm_add-to-cart btn-add${(productCount + minSaleUnits > stock && inventoryTracking) ? ' insufficient-stock' : ''}`} onClick={updateUnit}/>}
          </>
          }
        </AnimatePresence>
        {(displayStockWarning && customer) && <span className='last_units'>{t('product_page.product.last_units')}</span>}
      </ButtonWrapper>

    </>
  )
})
